import React from "react";
import logo from "~/assets/logo.svg";
import { Form, NavLink, useActionData, useNavigation } from "@remix-run/react";
import TextInput from "~/components/TextInput";
import { ActionFunctionArgs, json } from "@remix-run/node";
import ModalWrapperView from "~/components/ModalWrapper";
import staffLogo from "~/assets/staff.svg";
import workplaceLogo from "~/assets/workplace.svg";
import Button from "~/components/Button";
import { validateLoginForm } from "~/api/auth/auth.validation.server";
import { login } from "~/api/auth/auth.server";
import useGeneralErrorHandler from "~/hooks/useGeneralErrorHandler";

export const action = async ({ request }: ActionFunctionArgs) => {
  const { errors, fields } = await validateLoginForm(request);
  if (Object.keys(errors).length > 1) {
    return json({ errors });
  }
  return await login({ ...fields });
};

const LoginScreen = () => {

  const [isErrorHidden, setIsErrorsHidden] = React.useState<boolean>(false);
  const actionData = useActionData<typeof action>();
  const navigation = useNavigation();
  const isSubmitting = navigation.state === "submitting";

  const [showModal, setShowModal] = React.useState<boolean>(false);

  useGeneralErrorHandler({ 
    errors: actionData?.errors,
    onError:() => setIsErrorsHidden(false)
  })

  return (
    <>
      <Form method="post" className="mt-8 sm:min-w-96 min-w-60 max-sm:w-full max-sm:min-h-full items-center justify-center flex flex-col relative">
        <div className="flex-1 w-full max-sm:h-full max-sm:px-4">
          <img className="w-60 block mx-auto" src={logo} alt="logo" />
          <div className="mt-5 text-center">
            <p className="text-3xl font-semibold">Login</p>
            <div className="mt-3">
              <p className="font-medium text-gray-400 text-lg">
                Welcome back to Time2Staff!
              </p>
            </div>
          </div>
          <div className="w-full">  
            <TextInput
              className="mt-5"
              type="email"
              title="E-mail address"
              errorMessage={isErrorHidden ? undefined : actionData?.errors.username}
              fieldName="username"
              placeholder="Please enter your email"
            />
            <TextInput
              className="mt-4"
              type="password"
              errorMessage={isErrorHidden ? undefined : actionData?.errors.password}
              title="Password"
              fieldName="password"
              placeholder="Please enter your password"
            />
          </div>
          <Button 
            type="submit" 
            title="Login" 
            className="max-sm:hidden mt-5 bg-staff-orange" 
            onClick={() => setIsErrorsHidden(true)} 
            isLoading={isSubmitting} />
          <div className="flex flex-row gap-1 mt-4 w-full justify-center">
            <p className="font-medium text-gray-400 text-base">
              {"Don't have an account?"}
            </p>
            <button
              type="button"
              className="text-staff-orange text-base font-semibold bg-transparent border-none"
              onClick={() => setShowModal(true)}
            >
              Create one
            </button>
          </div>
          <div className="w-full text-center">
            <NavLink
              to={"/forget-pass"}
              className="text-staff-orange font-bold text-base font-semibold"
            >
              Forgot password?
            </NavLink>
          </div>
        </div>
        <div className="sm:hidden border-t-1 border border-black border-opacity-15 p-4 w-full">
          <Button 
            type="submit" 
            title="Login" 
            className="bg-staff-orange"
            onClick={() => setIsErrorsHidden(true)} 
            isLoading={isSubmitting} />
        </div>
      </Form>

      {showModal ? (
        <ModalWrapperView requestToClose={() => setShowModal(false)}>
          <div className="rounded-lg shadow-lg relative flex flex-col bg-white outline-none focus:outline-none p-5 w-96">
            <div className="w-full text-center">
              <h3 className="text-3xl font-regular font-normal">
                Sign up as a:
              </h3>
            </div>
            <div className="mt-5">
              <NavLink
                to={"/staff/signup"}
                className="border-0 gap-3 flex flex-row w-full border-b border-disabled-gray border-opacity-25 border-solid items-center justify-stretch pb-7"
              >
                <img
                  src={staffLogo}
                  alt="staff"
                  className="overflow-hidden rounded-lg aspect-square w-14"
                />
                <div className="flex font-regular flex-col justify-between items-start">
                  <p className="text-staff-orange text-xl font-bold">Staff</p>
                  <p className="text-disabled-gray text-base">
                    I’m looking for jobs
                  </p>
                </div>
              </NavLink>
              <NavLink
                to={"/client/signup"}
                type="button"
                className="border-0 gap-3 flex flex-row w-full items-center justify-stretch mt-7"
              >
                <img
                  src={workplaceLogo}
                  alt="workplace"
                  className="overflow-hidden rounded-lg aspect-square w-14"
                />
                <div className="flex font-regular flex-col justify-between items-start">
                  <p className="text-workplace-blue text-xl font-bold">
                    Customer
                  </p>
                  <p className="text-disabled-gray text-base">
                    {"I'm looking for staff"}
                  </p>
                </div>
              </NavLink>
            </div>
          </div>
        </ModalWrapperView>
      ) : null}
    </>
  );
};

export default LoginScreen;
